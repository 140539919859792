import validate from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/node_modules/.pnpm/nuxt@3.12.4_@types+node@22.4.1_eslint@9.9.0_rollup@4.21.0_sass@1.77.8_typescript@5.5.4_vite@5.4.1_vue-tsc@2.0.29/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-stock-portfolio-type-valid": () => import("/usr/src/packages/stocks/middleware/is-stock-portfolio-type-valid.ts"),
  "authenticated-or-login": () => import("/usr/src/apps/base/src/middleware/authenticated-or-login.ts"),
  "existing-product-id": () => import("/usr/src/apps/base/src/middleware/existing-product-id.ts"),
  "hebrew-only": () => import("/usr/src/apps/base/src/middleware/hebrew-only.ts"),
  "not-authenticated-only": () => import("/usr/src/apps/base/src/middleware/not-authenticated-only.ts")
}